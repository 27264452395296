import request from "@/utils/request";

// 广告配置
export function advertisementSave(data) {
  return request({
    url: "/marketing-web-api/admin/shootTopicAd/save",
    method: "POST",
    data,
  });
}

// 获取广告配置
export function getAdvertisementDetail(data) {
  return request({
    url: "/marketing-web-api/admin/shootTopicAd/detail",
    method: "POST",
    data,
  });
}

// 还原上一次广告配置
export function reductionAdvertisement(data) {
  return request({
    url: "/marketing-web-api/admin/shootTopicAd/reduce",
    method: "POST",
    data,
  });
}

// 获取动态列表
export function getMomentsList(data) {
  return request({
    url: "/marketing-web-api/admin/shootTopic/getList",
    method: "POST",
    data,
  });
}

// 动态置顶 审核 精选操作
export function momentsOperate(data) {
  return request({
    url: "/marketing-web-api/admin/shootTopic/check",
    method: "POST",
    data,
  });
}

// 举报记录分页列表
export function reportRecordList(data) {
  return request({
    url: "/marketing-web-api/admin/shootTopic/getTopicReportList",
    method: "POST",
    data,
  });
}

// 获取动态详情
export function getMomentsDetail(data) {
  return request({
    url: "/marketing-web-api/admin/shootTopic/detail",
    method: "POST",
    data,
  });
}

// 获取评论分页
export function getCommentList(data) {
  return request({
    url: "/marketing-web-api/admin/shootTopicComment/getList",
    method: "POST",
    data,
  });
}

// 评论 屏蔽和通过
export function commentMaskOrPass(data) {
  return request({
    url: "/marketing-web-api/admin/shootTopicComment/shield",
    method: "POST",
    data,
  });
}

// 获取话题分类分页列表
export function getTopicCategoryList(data) {
  return request({
    url: "/marketing-web-api/admin/shootTopicSubjectCategory/pageList",
    method: "POST",
    data,
  });
}

// 添加话题分类
export function addTopicCategory(data) {
  return request({
    url: "/marketing-web-api/admin/shootTopicSubjectCategory/add",
    method: "POST",
    data,
  });
}

// 更新话题分类
export function updateTopicCategory(data) {
  return request({
    url: "/marketing-web-api/admin/shootTopicSubjectCategory/updateById",
    method: "POST",
    data,
  });
}

// 更新话题分类状态
export function updateTopicCategoryState(data) {
  return request({
    url: "/marketing-web-api/admin/shootTopicSubjectCategory/updateDisableById",
    method: "POST",
    data,
  });
}

// 获取话题分页列表
export function getTopicList(data) {
  return request({
    url: "/marketing-web-api/admin/shootTopicSubject/pageList",
    method: "POST",
    data,
  });
}

// 更新话题禁用状态
export function updateTopicIsDisable(data) {
  return request({
    url: "/marketing-web-api/admin/shootTopicSubject/updateDisableById",
    method: "POST",
    data,
  });
}

// 更新话题置顶状态
export function updateTopicIsTop(data) {
  return request({
    url: "/marketing-web-api/admin/shootTopicSubject/updateTopById",
    method: "POST",
    data,
  });
}

// 通过id查询话题详情
export function getTopicDetail(data) {
  return request({
    url: "/marketing-web-api/admin/shootTopicSubject/findListByCondition",
    method: "POST",
    data,
  });
}

// 通过id查询话题数据详情
export function getTopicNumData(data) {
  return request({
    url: "/marketing-web-api/admin/shootTopicSubject/findSubjectStatById",
    method: "POST",
    data,
  });
}

// 查询话题分类
export function getTopicCategory(data) {
  return request({
    url: "/marketing-web-api/admin/shootTopicSubjectCategory/findListByCondition",
    method: "POST",
    data,
  });
}

// 获取标签树
export function getTagTree(data) {
  return request({
    url: "tag-web-api/admin/tag/getTagTree",
    method: "POST",
    data,
  });
}

// 添加话题
export function addTopic(data) {
  return request({
    url: "marketing-web-api/admin/shootTopicSubject/add",
    method: "POST",
    data,
  });
}

// 更新话题
export function updateTopic(data) {
  return request({
    url: "marketing-web-api/admin/shootTopicSubject/updateById",
    method: "POST",
    data,
  });
}

// 获取动态分类分页列表
export function getMomentsCategoryList(data) {
  return request({
    url: "marketing-web-api/admin/shootTopicCategory/pageList",
    method: "POST",
    data,
  });
}

// 禁用动态分类
export function momentsCategoryDisable(data) {
  return request({
    url: "marketing-web-api/admin/shootTopicCategory/updateDisableById",
    method: "POST",
    data,
  });
}

// 添加动态分类
export function addMomentsCategory(data) {
  return request({
    url: "marketing-web-api/admin/shootTopicCategory/add",
    method: "POST",
    data,
  });
}

// 更新动态分类
export function updateMomentsCategory(data) {
  return request({
    url: "marketing-web-api/admin/shootTopicCategory/updateById",
    method: "POST",
    data,
  });
}
