<template>
  <div class="topicDetail">
    <div class="topicDetail-header">
      <span>话题详情</span>
    </div>

    <div class="content-item">
      <div class="item-header">基本信息</div>
      <div class="item-body">
        <div style="margin-right: 114px" class="body-element">
          <div class="element-row">
            <span class="element-label">话题封面：</span>
            <span class="image-container">
              <img width="88px" height="88px" :src="formData.coverUrl" />
            </span>
          </div>
        </div>

        <div style="margin-right: 180px" class="body-element">
          <div class="element-row">
            <span class="element-label">话题名称：</span>
            <span class="element-content short-text">
              {{ formData.subjectName }}
            </span>
          </div>

          <div class="element-row textNum">
            <span class="element-label">话题ID：</span>
            <span class="element-content short-text">
              {{ formData.subjectCode }}
            </span>
          </div>

          <div class="element-row">
            <span class="element-label">话题状态：</span>
            <span class="element-content short-text">{{
              formData.isDisable ? "已禁用" : "已启用"
            }}</span>
          </div>
        </div>

        <div style="margin-right: 180px" class="body-element">
          <div class="element-row">
            <span class="element-label">话题分类：</span>
            <span class="element-content short-text">
              {{ formData.categoryName }}
            </span>
          </div>

          <div class="element-row">
            <span class="element-label">创建人：</span>
            <span class="element-content short-text">
              {{ formData.updateUserName }}
            </span>
          </div>

          <div class="element-row textNum">
            <span class="element-label">创建时间：</span>
            <span class="element-content short-text">
              {{ formData.updateTime }}
            </span>
          </div>
        </div>

        <div class="body-element">
          <div class="element-row">
            <span class="element-label">关联标签：</span>
            <span class="tags">
              <span
                v-for="item in formData.subjectTagValueList"
                :key="item"
                class="tag"
                :style="'background:' + item.colour"
                >#{{ item.tagValue }}</span
              >
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="content-item">
      <div class="item-header">话题简介</div>
      <div class="item-body">
        <div style="margin-right: 100px" class="body-element">
          <div class="element-row">
            <span class="element-label">话题简介：</span>
            <span style="width: 400px" class="element-content">
              {{ formData.description }}
            </span>
          </div>
        </div>

        <div class="body-element">
          <div class="element-row">
            <span class="element-label">详情配图：</span>
            <span class="image-container-detail">
              <img width="176px" height="88px" :src="formData.detailUrl" />
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="content-item">
      <div class="item-header">基础数据</div>
      <div class="form-container">
        <div class="form">
          <div class="header-row">
            <span class="item">动态数量</span>
            <span class="item">评论数量</span>
            <span class="item">点赞数量</span>
            <span class="item">浏览次数</span>
            <span class="item">热度</span>
          </div>
          <div class="row">
            <span class="item">{{ NumData.topicNum }}</span>
            <span class="item">{{ NumData.commentNum }}</span>
            <span class="item">{{ NumData.goodsNum }}</span>
            <span class="item">{{ NumData.browseNum }}</span>
            <span class="item">{{ NumData.heat }}</span>
          </div>
        </div>

        <div class="tips">
          <i class="el-icon-warning"></i>
          <span style="margin-left: 3px"
            >热度=动态数量*40%+评论数量*30%+点赞数量*20%+浏览次数*10%</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTopicDetail, getTopicNumData } from "@/api/friendCircle.js";

export default {
  data() {
    return {
      formData: {},
      NumData: {},
    };
  },
  computed: {
    id() {
      return this.$route.query.id || "";
    },
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    async getDetail() {
      const reqObj = {
        data: {
          subjectId: this.id,
        },
      };

      const allRes = await Promise.all([
        getTopicDetail(reqObj),
        getTopicNumData({ data: this.id }),
      ]);

      const detailRes = allRes[0];
      const numData = allRes[1];

      if (detailRes.success) {
        this.formData = detailRes.data[0];
      }

      if (numData.success) {
        this.NumData = numData.data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.topicDetail {
  background: #ffffff;
  .topicDetail-header {
    padding: 9px 16px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  .topicDetail-header::before {
    content: "";
    height: 18px;
    border-left: 3px solid #363f47;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    position: absolute;
    left: 0;
    top: 10px;
  }

  .topicDetail-header::after {
    content: "";
    background: #e0e0e0;
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .content-item {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 16px;
    border-bottom: 1px solid #ededed;
    padding-bottom: 36px;

    .item-header {
      font-size: 20px;
    }

    .item-body {
      display: flex;
      margin-top: 20px;

      .body-element {
        .element-row {
          display: flex;
          margin-bottom: 20px;

          .element-label {
            color: #9a9d9e;
            font-size: 14px;
            white-space: nowrap;
          }

          .element-content {
            color: #000;
            font-size: 14px;
          }

          .short-text {
            white-space: nowrap !important;
          }
        }

        .textNum {
          align-items: center;
        }

        .tags {
          display: flex;
          position: relative;
          top: -3px;
          flex-wrap: wrap;

          .tag {
            border-radius: 4px;
            margin-right: 10px;
            padding: 3px 8px;
            color: #000;
            font-size: 14px;
            margin-bottom: 5px;
          }
        }
      }

      .element-row:last-child {
        margin-bottom: unset;
      }
    }

    .image-container {
      width: 104px;
      height: 104px;
      border-radius: 4px;
      background: #f5f5f5;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #e0e0e0;

      &-detail {
        width: 195px;
        height: 104px;
        border-radius: 4px;
        background: #f5f5f5;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #e0e0e0;
      }
    }
  }

  .form-container {
    margin-top: 20px;

    .form {
      border-bottom: 1px solid #e0e0e0;
      border-right: 1px solid #e0e0e0;
      display: inline-block;

      .header-row {
        background: #f5f5f5;
        display: flex;
      }

      .row {
        display: flex;
      }

      .item {
        border-top: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
        font-size: 14px;
        padding: 10px 16px;
        width: 150px;
        box-sizing: border-box;
      }

      .item:last-child {
        color: #e86247;
      }
    }

    .tips {
      color: #9a9d9e;
      font-size: 12px;
      margin-top: 8px;
    }
  }
}
</style>
